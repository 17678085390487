import React from 'react'
import {
  Button,
  Box,
  Dialog,
  MenuItem,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  makeStyles,
  createStyles,
  FormControl,
  InputLabel,
} from '@material-ui/core'
import * as yup from 'yup'
import { Formik, Field, Form } from 'formik'
import { TextField, CheckboxWithLabel, Select } from 'formik-material-ui'
import { CurrencyCodeEnum, ProgramTypeEnum } from '../gql-global'
import { PROGRAM_TYPE_NAME_MAP } from './constants'
import { formatCurrencyCodeDisplay } from '../utils/currency'
import { useAccountSocialAccountsQuery } from './operations/account-social-accounts.generated'
import { isTypeName } from '../types/utility'

const CURRENCY_CODE_DESCRIPTION_CHARACTER_LIMIT = 30

const useStyles = makeStyles(() =>
  createStyles({
    dropDownField: {
      marginTop: '12px',
    },
  }),
)

const schema = yup.object({
  name: yup.string().trim().required(),
  igSocialAccountId: yup.string(),
  ttSocialAccountId: yup.string(),
  programType: yup.string().required(),
  integrationId: yup.string(),
  ecommIntegrationId: yup.string(),
  requiresApproval: yup.boolean(),
  requiresVerification: yup.boolean(),
  currencyCode: yup.string(),
})

export type AddCampaignFields = yup.InferType<typeof schema>

const defaultValues: AddCampaignFields = {
  name: '',
  igSocialAccountId: '',
  ttSocialAccountId: '',
  programType: ProgramTypeEnum.AllCustomer,
  requiresVerification: false,
  requiresApproval: false,
  integrationId: '',
  ecommIntegrationId: '',
  currencyCode: CurrencyCodeEnum.Usd,
}

interface AddCampaignDialogProps {
  canCreate: boolean
  onClose(): void
  open: boolean
  onCreate(campaign: AddCampaignFields): void
  refersionIntegrations: { id: string; name: string }[]
  ecommIntegrations: { id: string; name: string }[]
}

function AddCampaignDialog({
  canCreate,
  open,
  onClose,
  onCreate,
  refersionIntegrations,
  ecommIntegrations,
}: AddCampaignDialogProps): React.ReactElement {
  const classes = useStyles()

  const { data: accountSocialAccounts } = useAccountSocialAccountsQuery()

  const igSocialAccountOptions =
    accountSocialAccounts?.whoami?.account?.socialAccounts
      ?.filter(sa => isTypeName(sa, 'IGSocialAccount'))
      .map(sa => {
        return {
          id: sa.id,
          name: sa.socialUser?.username || '',
        }
      }) || []
  const ttSocialAccountOptions =
    accountSocialAccounts?.whoami?.account?.socialAccounts
      ?.filter(sa => isTypeName(sa, 'TTSocialAccount'))
      .map(sa => {
        return {
          id: sa.id,
          name: sa.socialUser?.username || '',
        }
      }) || []

  function onSubmit(campaign: AddCampaignFields): void {
    onCreate(campaign)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Program</DialogTitle>
      {canCreate ? (
        <Formik<AddCampaignFields>
          initialValues={{
            ...defaultValues,
            // select ecomm integration by default if only one is available
            ecommIntegrationId: ecommIntegrations.length === 1 && !!ecommIntegrations[0] ? ecommIntegrations[0].id : '',
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {props => (
            <Form noValidate>
              <DialogContent>
                <Box display="flex" flexDirection="column" minWidth={300}>
                  <Field component={TextField} label="Name" name="name" />
                  <Field
                    name="igSocialAccountId"
                    component={TextField}
                    select
                    label="Instagram Brand Account"
                    className={classes.dropDownField}
                  >
                    {igSocialAccountOptions.map(sa => (
                      <MenuItem key={sa.id} value={sa.id}>
                        {sa.name}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    name="ttSocialAccountId"
                    component={TextField}
                    select
                    label="TikTok Brand Account"
                    className={classes.dropDownField}
                  >
                    {ttSocialAccountOptions.map(sa => (
                      <MenuItem key={sa.id} value={sa.id}>
                        {sa.name}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    Label={{ label: 'Requires Member Approval' }}
                    name="requiresApproval"
                  />
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    Label={{ label: 'Requires Content Verification' }}
                    name="requiresVerification"
                  />
                  <Field name="programType" component={Select} label="Program Type" className={classes.dropDownField}>
                    {Object.values(ProgramTypeEnum).map(programType => (
                      <MenuItem key={programType} value={programType}>
                        {PROGRAM_TYPE_NAME_MAP[programType]}
                      </MenuItem>
                    ))}
                  </Field>
                  <FormControl>
                    <InputLabel id="integrationIdLabel">Source Integration</InputLabel>
                    <Field
                      name="integrationId"
                      component={Select}
                      labelId="integrationIdLabel"
                      className={classes.dropDownField}
                    >
                      <MenuItem value="">None</MenuItem>
                      {refersionIntegrations.map(i => (
                        <MenuItem key={i.id} value={i.id}>
                          {i.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                  <FormControl>
                    <InputLabel id="ecommIntegrationLabel"> Ecomm Integration</InputLabel>
                    <Field
                      name="ecommIntegrationId"
                      component={Select}
                      className={classes.dropDownField}
                      id="ecommIntegration"
                      labelId="ecommIntegrationLabel"
                    >
                      <MenuItem value="" selected={true}>
                        {props.values.programType === ProgramTypeEnum.ShoppableAmbassador
                          ? 'Other Ecomm Platform'
                          : 'None'}
                      </MenuItem>
                      {ecommIntegrations.map(i => (
                        <MenuItem key={i.id} value={i.id} selected={true}>
                          {i.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                  <Field name="currencyCode" component={Select} label="Currency Code" className={classes.dropDownField}>
                    {Object.keys(CurrencyCodeEnum).map(code => (
                      <MenuItem key={code.toUpperCase()} value={code.toUpperCase()}>
                        {formatCurrencyCodeDisplay(code.toUpperCase(), CURRENCY_CODE_DESCRIPTION_CHARACTER_LIMIT)}
                      </MenuItem>
                    ))}
                  </Field>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Dismiss</Button>
                <Button type="submit">Save</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <DialogContent>
            Let&apos;s create a growth campaign! Please contact{' '}
            <Link href="mailto:support@loudcrowd.com">support@loudcrowd.com</Link> or your account manager to design,
            plan and execute a campaign!
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Dismiss</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default AddCampaignDialog
